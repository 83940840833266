<template style="height:auto;">
    <div style="height:auto;min-width: 1000px;">
        <div style="text-align:center; margin-top: 10px;margin-bottom: 10px;">
            <el-button type="primary" size="mini" icon="el-icon-full-screen" @click="launchFullscreen()">全屏</el-button>
            <el-button type="primary" size="mini" icon="el-icon-d-arrow-left" @click="previousPage()">上一页</el-button>
            <el-button type="primary" size="mini" icon="el-icon-d-arrow-right" @click="nextPage()">下一页</el-button>
            <el-button type="primary" size="mini" icon="el-icon-arrow-left" @click="previousAnimation()">上一动画</el-button>
            <el-button type="primary" size="mini" icon="el-icon-arrow-right" @click="nextAnimation()">下一动画</el-button>
        </div>
        <iframe id="iframeOffice" style="height:100%;" width="100%" frameborder='no' border='0' marginwidth='0'
            marginheight='0' scrolling='no' allowtransparency='yes' :src="pathUrl"></iframe>
        <bottom-bar class="course-bottom" />
    </div>
</template>

<script>
import { ChapterInfo, UpdatePage, UpdateTime } from '@/api/coursedetail'
import { getInfo } from '@/api/login'

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import NavBar from "../../components/content/navBar/NavBar"
import BottomBar from '../../components/content/bottomBar/BottomBar'

import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'CourseDetail',
    components: {
        NavBar,
        BottomBar
    },
    computed: {
    },
    data() {
        return {
            currentTime: new Date().toLocaleString(),
            //配置的网站ID
            officewebid: 31664,
            //当前页数
            currentPage: 0,
            //总页数
            pageCount: 0,
            //文件url
            pathUrl: '',
            //节点ID
            chapterId: '',
            //累加时间值默认为1
            secondTime: 1,
            //用户信息
            users: {},
            //总时长
            totalSecond: 0,
            //已讲解时间
            courseSecond: 0,
        }
    },
    methods: {
        //用户信息
        async userInfo() {
            var token = getToken();
            if (token) {
                await getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //登录
        requestLogin() {
            this.$router.push('/login');
        },
        //退出登录
        loginOut() {
            removeToken();
            this.$router.push('/login');
        },
        //跳转登录后首页
        requestLoginHome() {
            this.$router.push('/loginHome');
        },
        //节点信息
        async Chapter() {
            this.listLoading = true
            this.chapterId = this.$route.query.id;
            //1:课件2:教案3:学生讲义
            let fileType = this.$route.query.type;
            await ChapterInfo({ 'id': this.chapterId, 'loginId': this.users.id }).then(response => {
                if (response) {
                    if (response.status == 200) {
                        // if (fileType == 1) {
                        //     this.pathUrl = response.data.teachPPT;
                        // } else if (fileType == 2) {
                        //     this.pathUrl = response.data.handoutPPT;
                        // } else if (fileType == 3) {
                        //     this.pathUrl = response.data.stuManual;
                        // }
                        if (fileType == 1) {
                            this.pathUrl = 'https://vip.ow365.cn/?i=31664&n=5&draw=1&furl=' + encodeURIComponent(response.data.teachPPT);
                        } else if (fileType == 2) {
                            this.pathUrl = 'https://vip.ow365.cn/?i=31664&n=5&draw=1&furl=' + encodeURIComponent(response.data.handoutPPT);
                        } else if (fileType == 3) {
                            this.pathUrl = 'https://vip.ow365.cn/?i=31664&n=5&draw=1&furl=' + encodeURIComponent(response.data.stuManual);
                        }
                        this.courseSecond += response.data.timeLength;
                        this.totalSecond = response.data.totalTime;
                        this.courseId = response.data.courseId
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                } else {
                    MessageBox.alert("获取节点信息错误", "错误", {
                        confirmButtonText: '确定',
                        type: 'error'
                    })
                }
                setTimeout(() => {
                    this.listLoading = false
                }, 1.5 * 1000)
            })
        },
        //更新页码
        Pages() {
            this.listLoading = true
            this.chapterId = this.$route.query.id;
            UpdatePage({ 'chapterId': this.chapterId, "loginId": this.users.id, 'totalPage': this.pageCount, 'currentPage': this.currentPage }).then(response => {
                if (response) {
                }
                setTimeout(() => {
                    this.listLoading = false
                }, 1.5 * 1000)
            })
        },
        //添加或修改学校，年级，课程，课程类型信息
        UTime() {
            this.listLoading = true
            this.chapterId = this.$route.query.id;
            if (this.$route.query.type == 1) {
                UpdateTime(
                    {
                        'chapterId': this.chapterId,
                        "loginId": this.users.id,
                        "schoolId": this.$route.query.schoolId,
                        "gradeId": this.$route.query.gradeId,
                        "courseId": this.$route.query.courseId,
                        "courseTypeId": this.$route.query.courseTypeId,
                        "secondTime": this.secondTime
                    }
                ).then(response => {
                    if (response) {
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        courseOut() {
            this.$router.push({
                name: '/courseDetail',
                params: {
                    "id": this.courseId
                }
            });
        },
        //禁用鼠标右击、F12 来禁止打印和打开调试工具
        prohibit() {
            document.oncontextmenu = function () {
                return false
            }
            document.onkeydown = function (e) {
                if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e.keyCode === 117)) {
                    return false
                }
                if (e.keyCode === 18 || e.keyCode === 123) {
                    return false
                }
            }
        },
        //全屏
        launchFullscreen() {
            var element = document.getElementById('iframeOffice');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen(); //火狐
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen(); //ie浏览器
                document.getElementById("fullScreen").style.height = window.screen.height + "px";
                document.getElementById("fullScreen").style.width = document.documentElement.clientWidth + "px";
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen(); //谷歌浏览器
            }
        },
        //上一页
        previousPage() {
            var element = document.getElementById('iframeOffice');
            element.contentWindow.postMessage("prePage", '*');
        },
        //下一页
        nextPage() {
            var element = document.getElementById('iframeOffice');
            element.contentWindow.postMessage("nextPage", '*');
        },
        //上一动画
        previousAnimation() {
            var element = document.getElementById('iframeOffice');
            element.contentWindow.postMessage("preAnim", '*');
        },
        //下一动画
        nextAnimation() {
            var element = document.getElementById('iframeOffice');
            element.contentWindow.postMessage("nextAnim", '*');
        },
        // //显示画笔
        // showPen() {
        //     var element = document.getElementById('iframeOffice');
        //     element.contentWindow.postMessage("show,p", "*");
        // },
        // //隐藏画笔
        // hidePen() {
        //     var element = document.getElementById('iframeOffice');
        //     element.contentWindow.postMessage("hide,p", "*");
        // },
        // //隐藏全屏
        // hideFullScr() {
        //     var element = document.getElementById('iframeOffice');
        //     element.contentWindow.postMessage("hide,h", "*");
        // }
    },
    created() {
        //禁用鼠标右击、F12 来禁止打印和打开调试工具
        //this.prohibit();
        // 初次请求
        Promise.all([this.userInfo()]).then(() => {
            //节点信息
            this.Chapter();
            //累加时间
            this.UTime();
        });

        //调用接口记录时间
        setInterval(() => {
            const now = new Date();
            this.chapterId = this.$route.query.id;
            this.secondTime = 5;
            this.UTime();
            this.currentTime = now.toLocaleString();
        }, 5000);
    },
    mounted() {
        const oIframe = document.getElementById('iframeOffice');
        const windowsWidth = window.innerWidth;
        const windowsHeight = window.innerHeight;
        oIframe.style.width = (Number(windowsWidth) - 40) + 'px'; //数字是页面布局宽度差值
        oIframe.style.height = (Number(windowsHeight) - 60) + 'px'; //数字是页面布局高度差

        //iframe-宽高自适应显示
        // const oIframe = document.getElementById('iframeOffice');
        // const deviceWidth = document.documentElement.clientWidth;
        // const deviceHeight = document.documentElement.clientHeight;
        // oIframe.style.width = (Number(deviceWidth) - 30) + 'px'; //数字是页面布局宽度差值
        // oIframe.style.height = (Number(deviceHeight) - 40) + 'px'; //数字是页面布局高度差

        /*按照翻页来计算百分比的先注释*/
        // this.$nextTick(() => {
        //     let _self = this;
        //     window.addEventListener("message", function (event) {
        //         if (event.data && typeof (event.data) != 'object') {
        //             var result = JSON.parse(event.data);
        //             this.pageCount = result.page.total;
        //             this.currentPage = result.page.idx;
        //             let chapterid = localStorage.getItem("chapterid");
        //             let userid = localStorage.getItem("userid");
        //             let type = localStorage.getItem("type");
        //             if (type == 1) {
        //                 UpdatePage({ 'chapterId': chapterid, "loginId": userid, 'totalPage': this.pageCount, 'currentPage': this.currentPage }).then(response => {
        //                     if (response) {
        //                     }
        //                     setTimeout(() => {
        //                         this.listLoading = false
        //                     }, 1.5 * 1000)
        //                 });
        //             }
        //         }
        //     })
        // });
    }
}
</script>

<style scoped lang="less">
* {
    font-family: inherit;
}

a {
    margin-left: 20px;
    text-decoration: none;
}

h1 {
    font-size: 20px;
}

.bt {
    border-style: none;
    border: 1px solid #5bc0de;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 100;
    color: #fff;
    background-color: #5bc0de;
    width: 80px;
    height: 30px;
    margin-left: 5px;
}

.bt:hover {
    background-color: #5dcfe6;
}

.vals {
    border-style: none;
    border: 1px solid #5bc0de;
    outline: none;
    height: 30px;
    width: px;
    cursor: pointer;
    border-radius: 100%;
    font-size: 11px;
    text-align: center;
    line-height: 25px;
    font-weight: 100;
    color: #fff;
    background-color: #5bc0de;
}
</style>