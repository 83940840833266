import request from "@/utils/request";  //导入封装请求的js文件

//课程节点信息
export function CourseInfo(query) {
	return request({
		url: '/Chapter/GetCourseChapter',
		method: 'get',
		params: query
	})
}
//获取节点信息
export function ChapterInfo(query) {
	return request({
		url: '/Chapter/Get',
		method: 'get',
		params: query
	})
}
//修改页码
export function UpdatePage(data) {
	return request({
		url: '/Chapter/UpdatePage',
		method: 'post',
		data
	})
}

//定时添加或修改学校，年级，课程，课程类型信息
export function UpdateTime(data) {
	return request({
		url: '/Chapter/UpdateTime',
		method: 'post',
		data
	})
}

//获取课程权限
export function CourseRole(query) {
	return request({
		url: '/Chapter/GetCourseRole',
		method: 'get',
		params: query
	})
}